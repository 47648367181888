import { useRouter } from 'next/router';
import { i18n } from 'next-i18next.config';
import { useEffect, useState } from 'react';

import { Product } from '@commerce/types/product';
import { getProductBySKU } from '@components/product/helpers';

/**
 * custom hook to fetch product data by sku
 * @returns { product: Product | null; loading: boolean }
 */
export default function useProductData(sku?: string): { product: Product | null; loading: boolean } {
  const { locale = i18n.defaultLocale } = useRouter();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getProduct(productSku: string) {
      setLoading(true);
      const { data } = await getProductBySKU(productSku, locale);
      if (data?.id) {
        setProduct(data);
      }
      setLoading(false);
    }

    if (sku && !product) {
      getProduct(sku);
    }
  }, [product, sku, locale]);

  return { product, loading };
}
