import { Entry, EntrySkeletonType } from 'contentful';
import { FC } from 'react';

import style from '@components/blogPage/BlogDetail/BlogDetail.module.scss';
import { RichTextRenderer } from '@components/common/RichText/factory';
import { mapFields } from '@components/common/RichText/helpers';
import { ModuleProduct } from '@components/common/types/ModuleProduct';
import { isTileGps } from '@components/product/helpers';
import useProductData from '@components/product/ProductListing/hooks/useProductData';
import Button from '@components/ui/Button/Button';
import { useAddToCart } from '@lib/hooks/useAddToCart';

type Props = {
  buttonCta: string;
  product: ModuleProduct;
};

type RendererEntryProps = Entry<EntrySkeletonType<Props>>;

const BlogCta: FC<Props> = ({ buttonCta, product }: Props) => {
  const [atcLoading, onAddToCart] = useAddToCart();
  const { sku, name: productName, bigCommerceId: productId } = product;

  const { product: productData, loading: initLoading } = useProductData(sku);

  return !isTileGps(productData) ? (
    <Button
      className={style.button}
      variant="cta"
      onClick={() => onAddToCart(productId.toString(), sku, productName)}
      aria-label={buttonCta}
      disabled={initLoading || atcLoading}
      loading={initLoading || atcLoading}
    >
      {buttonCta}
    </Button>
  ) : null;
};

const mapper = (entry: RendererEntryProps) => mapFields(entry);

export default RichTextRenderer({ name: 'blogCta', mapper })(BlogCta);
